export const SET_FILTERED_PARRAINS = 'SET_FILTERED_PARRAINS';
export const SET_PARRAIN_ACTUEL = 'SET_PARRAIN_ACTUEL';
export const SET_TOUS_LES_PARRAINS_DISPOS = 'SET_TOUS_LES_PARRAINS_DISPOS';
export const REMOVE_PARRAIN = 'REMOVE_PARRAIN';
export const UPDATE_PARRAIN = 'UPDATE_PARRAIN';
export const ADD_PARRAIN_TO_FILTERED = 'ADD_PARRAIN_TO_FILTERED';
export const SET_NB_MAX_FILLEULS_ATTEINT = 'SET_NB_MAX_FILLEULS_ATTEINT';
export const MAX_ADDITIONAL_FILLEULS = 'MAX_ADDITIONAL_FILLEULS';

export function removeParrain(parrainId) {
    return {
        type: REMOVE_PARRAIN,
        parrainId
    }
}

export function updateParrain(parrain) {
    return {
        type: UPDATE_PARRAIN,
        parrain
    }
}

export function setTousParrainsDispos(tousParrainsDispos) {
    return {
        type: SET_TOUS_LES_PARRAINS_DISPOS,
        tousParrainsDispos
    }
}

export function setParrainActuel(parrainActuel) {
    return {
        type: SET_PARRAIN_ACTUEL,
        parrainActuel
    }
}

export function setFilteredParrains(parrains) {
    return {
        type: SET_FILTERED_PARRAINS,
        'parrains': parrains
    }
}

export function addParrainToFiltered(parrain) {
    return {
        type: ADD_PARRAIN_TO_FILTERED,
        'parrain': parrain
    }
}

export function setNbMaxFilleulsAtteint(isNbMaxFilleulsAtteint) {
    return {
        type: SET_NB_MAX_FILLEULS_ATTEINT,
        isNbMaxFilleulsAtteint
    }
}

export function changeMaxAdditionalFilleuls(maxAdditionalFilleuls) {
    return {
        type: MAX_ADDITIONAL_FILLEULS,
        maxAdditionalFilleuls
    }
}