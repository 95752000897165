import {
    TOGGLE_VIEW,
    TOGGLE_SHOW_PARRAINS_WITH_ALREADY_ENOUGH_FILLEULS
} from "../actions/app";

export default function filleuls (state = {'view': 'table', 'showParrainsWithAlreadyEnoughFilleuls': false}, action) {
    switch(action.type) {
        case TOGGLE_VIEW:
            return {
                ...state,
                'view': state.view === 'table' ? 'module' : 'table'
            }
        case TOGGLE_SHOW_PARRAINS_WITH_ALREADY_ENOUGH_FILLEULS:
            return {
                ...state,
                'showParrainsWithAlreadyEnoughFilleuls': !state.showParrainsWithAlreadyEnoughFilleuls
            }
        default:
            return state;
    }
}
