import React, { Component } from 'react'
import {connect} from "react-redux";
import {setFilleulActuel} from "../store/actions/filleuls";
import {actionMatchPair, actionPassFilleulActuel, actionSetSimilarParrains} from "../store/actions";
import {Button} from "react-bootstrap";
import SelectAjoutParrainManuel from "./SelectAjoutParrainManuel";

class TableViewRepartition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filleulActuel: {}
        };

        this.handlePassFilleul = this.handlePassFilleul.bind(this);
        this.handleMatchFilleulParrain = this.handleMatchFilleulParrain.bind(this);
        this.handlePrevFilleul = this.handlePrevFilleul.bind(this);
        this.handleNextFilleul = this.handleNextFilleul.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            'parrains': this.props.parrains,
            'filleuls': this.props.filleuls,
            'filleulActuel': this.props.filleulActuel,
            'currentFilleulIndex': this.props.filleulActuel,
            'totalFilleuls': this.props.totalFilleuls
        })
    }

    handlePassFilleul() {
        this.props.dispatch(actionPassFilleulActuel())
    }

    async handleMatchFilleulParrain(parrain) {
        // On récupère l'id du filleul actuel, qu'on passe avec le parrain pour créer la Pair, et on charge la liste des 
        // parrains potentiels pour le filleul suivant
        const filleulIdx = this.props.filleulActuel;
        await this.props.dispatch(actionMatchPair(parrain, this.props.filleuls[filleulIdx]));
        await this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel+1]));
    }

    handlePrevFilleul() {
        // Bouton désactivé si on est déjà au premier filleul
        if(this.state.currentFilleulIndex > 0) {
            // On revient au filleul précédent dans la liste, et on récupère la liste des parrains qui correspondent
            this.props.dispatch(setFilleulActuel(this.state.currentFilleulIndex-1));
            this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel-1]));
            
            this.setState({
                ...this.state,
                'currentFilleulIndex': this.state.currentFilleulIndex-1,
                'filleulActuel': this.props.filleuls[this.state.currentFilleulIndex-1]
            })
        }
    }

    handleNextFilleul() {
        // Bouton désactivé si on est déjà au dernier filleul de la liste
        if(this.props.filleulActuel < this.props.totalFilleuls) {
            // On passe au filleul suivant dans la liste, et on récupère la liste des parrains qui correspondent
            const nouveauFilleul = this.props.filleuls[this.props.filleulActuel+1];
            this.props.dispatch(setFilleulActuel(this.props.filleulActuel+1));
            this.props.dispatch(actionSetSimilarParrains(nouveauFilleul));

            this.setState({
                ...this.state,
                'currentFilleulIndex': this.props.filleulActuel+1,
                'filleulActuel': this.props.filleuls[this.state.currentFilleulIndex+1],
            })
        }
    }

    render() {
        const filleul = this.props.filleuls[this.props.filleulActuel];

        const styleBtns = {
            'marginTop': '20px'
        };

        return (
        <div className="module module-selection-filleul" style={{'width': '90%', 'margin': 'auto'}}>
            {/* Afficher le filleul sur une ligne, en haut de l'écran, avec les boutons de navigation juste en-dessous*/}
            { typeof filleul === undefined || this.props.filleuls.length === 0
                ? ( <div className="filleul-description">Il semble que tous les filleuls aient été répartis !</div> )
                : (
                    <div className="filleul-description" style={{'display': 'flex', 'flexDirection': 'column',
                        'height': '100%'}}>
                        <div style={styleBtns}>
                            <div className="btn-next-previous" style={styleBtns}>
                                <Button
                                    variant="primary"
                                    onClick={this.handlePrevFilleul}
                                    disabled={this.props.filleulActuel === 0}
                                    style={{'marginRight': '10px'}}
                                >{'<'}</Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleNextFilleul}
                                    disabled={this.props.filleulActuel+1 === this.props.totalFilleuls}
                                >{'>'}</Button>
                            </div>
                            <div className="counter filleul-counter">
                                { this.props.filleulActuel + 1 } / { this.props.totalFilleuls }
                            </div>
                            <Button variant="link" onClick={this.handlePassFilleul} style={{'marginBottom': '20px'}}>Passer</Button>
                        </div>

                        <div className='sets' style={{'marginBottom': '30px'}}>
                            <div className="list-attributes" style={{'border': '1px solid black', 'width': '50%', 'display': 'inline-block'}}>
                                <p>{ filleul.fullName }</p>
                                <p>{ filleul.year } { filleul.pathway }</p>
                                <p>{ filleul.center }
                                    { (filleul.year === 'Licence 1' || filleul.year === 'Licence 2')
                                    && filleul.center === 'Paris'
                                    && filleul.pathway === 'Droit'
                                        ? ' (' + filleul.equipeL1 + ')'
                                        : '' }</p>
                                <p style={{'marginBottom': '0'}}>{ filleul.comments ? (<i><b>Commentaires</b> : {filleul.comments}</i>) : (<i>Pas de commentaires</i>) }</p>
                            </div>
                            <div style={{'width': '50%', 'display': 'inline-block', 'verticalAlign': 'top'}}>
                                <SelectAjoutParrainManuel />
                            </div>
                        </div>

                        {this.props.parrains === undefined || !Array.isArray(this.props.parrains) || this.props.showParrainsWithAlreadyEnoughFilleuls
                            ? (<div className="parrain"><b>Les parrains ci-dessous sont compatibles, mais certains ont déjà atteint leur nombre de filleuls souhaités...</b></div>)
                            : (<div></div>)
                        }
                        <table style={{'width': '100%', 'margin': 'auto'}}>
                            <thead>
                            <tr>
                                <th width='5%'>Id</th>
                                <th width='10%'>Prénom</th>
                                <th width='10%'>Nom</th>
                                <th width='10%'>Année</th>
                                <th width='10%'>Filière</th>
                                <th width='10%'>Centre</th>
                                <th width='10%'>Équipe L1</th>
                                <th width='10%'>Filleuls souhaités</th>
                                <th width='20%'>Commentaires</th>
                                <th width='5%'></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.parrains.map((parrain, key) => (
                                <tr key={key}>
                                    <td>{parrain.id}</td>
                                    <td>{parrain.firstName}</td>
                                    <td>{parrain.name}</td>
                                    <td>{parrain.year}</td>
                                    <td>{parrain.pathway}</td>
                                    <td>{parrain.center}</td>
                                    <td>{parrain.equipeL1}</td>
                                    <td>{parrain.maxFilleuls}</td>
                                    <td>{parrain.comments}</td>
                                    <td><a href='#' onClick={() => this.handleMatchFilleulParrain(parrain)}>Matcher</a></td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                )
            }

        </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls, app }) {
    return {
        'parrains': parrains.parrains,
        'filleuls': filleuls.filleuls,
        'filleulActuel': filleuls.filleulActuel,
        'parrainActuel': parrains.parrainActuel,
        'tousParrainsDispos': parrains.tousParrainsDispos,
        'totalFilleuls': filleuls.filleuls.length,
        'showParrainsWithAlreadyEnoughFilleuls': app.showParrainsWithAlreadyEnoughFilleuls
    }
}

export default connect(mapStateToProps)(TableViewRepartition);