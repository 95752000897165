import React, { Component } from 'react';
import {connect} from "react-redux";
import { actionMatchPair, actionPassFilleulActuel, actionSetSimilarParrains } from "../store/actions";
import {  Button } from 'react-bootstrap'
import RecapCorrespondance from "./RecapCorrespondance";
import ModuleFilleuls from "./ModuleFilleuls";
import ModuleParrains from "./ModuleParrains";

class IndividualViewRepartition extends Component {
    constructor(props) {
        super(props);

        this.handlePassFilleul = this.handlePassFilleul.bind(this);
        this.handleMatchFilleulParrain = this.handleMatchFilleulParrain.bind(this);
    }


    async handleMatchFilleulParrain() {
        const parrainIdx = this.props.parrainActuel;
        const filleulIdx = this.props.filleulActuel;
        await this.props.dispatch(actionMatchPair(this.props.parrains[parrainIdx], this.props.filleuls[filleulIdx]));
        await this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel]));
    }

    handlePassFilleul() {
        this.props.dispatch(actionPassFilleulActuel())
    }

    render() {
        return (
            <div className="module" style={{'width': '90%', 'margin': 'auto'}}>
                <div className="btns btns-navigation" style={{'marginBottom': '70px', 'marginTop': '70px'}}>
                    <Button variant="link" onClick={this.handlePassFilleul}>Passer</Button>
                    <Button variant="success" onClick={this.handleMatchFilleulParrain}>Matcher</Button>
                </div>
                <div className="modules" style={{'display': 'flex', 'justifyContent': 'center'}}>
                    <ModuleFilleuls />
                    <RecapCorrespondance />
                    <ModuleParrains />
                </div>
            </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls, app }) {
    return {
        'parrains': parrains.parrains,
        'filleuls': filleuls.filleuls,
        'filleulActuel': filleuls.filleulActuel,
        'parrainActuel': parrains.parrainActuel
    }
}

export default connect(mapStateToProps)(IndividualViewRepartition);