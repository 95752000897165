import React, { Component } from 'react'
import { connect } from "react-redux";
import { setFilleulActuel} from "../store/actions/filleuls";
import { actionSetSimilarParrains} from "../store/actions";
import {Button} from "react-bootstrap";

class ModuleFilleuls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filleulActuel: {}
        };

        this.handlePrevFilleul = this.handlePrevFilleul.bind(this);
        this.handleNextFilleul = this.handleNextFilleul.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            'filleuls': this.props.filleuls,
            'filleulActuel': this.props.filleulActuel,
            'currentFilleulIndex': this.props.filleulActuel,
            'totalFilleuls': this.props.totalFilleuls
        })
    }

    handlePrevFilleul() {
        // Bouton désactiver si on est déjà au premier filleul
        if(this.state.currentFilleulIndex > 0) {
            // On change met à jour le filleul actuel, et on récupère la liste des parrains qui correspondent
            this.props.dispatch(setFilleulActuel(this.state.currentFilleulIndex-1));
            this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel-1]));
            this.setState({
                ...this.state,
                'currentFilleulIndex': this.state.currentFilleulIndex-1,
                'filleulActuel': this.props.filleuls[this.state.currentFilleulIndex-1]
            })
        }
    }

    handleNextFilleul() {
        if(this.props.filleulActuel < this.props.totalFilleuls) {
            const nouveauFilleul = this.props.filleuls[this.props.filleulActuel+1];
            this.props.dispatch(setFilleulActuel(this.props.filleulActuel+1));
            this.props.dispatch(actionSetSimilarParrains(nouveauFilleul));

            this.setState({
                ...this.state,
                'currentFilleulIndex': this.props.filleulActuel+1,
                'filleulActuel': this.props.filleuls[this.state.currentFilleulIndex+1],
            })
        }
    }

    render() {
        // Styles
        const styleModuleSelectionFilleul = {
            'width': '30%',
            'height': '300px'
        };

        const styleBtns = {
            'marginTop': 'auto'
        }

        const styleNoBullet = {
            'listStyleType': 'none',
            'padding': 0
        };

        const filleul = this.props.filleuls[this.props.filleulActuel];

        return (
            <div className="module module-selection-filleul" style={styleModuleSelectionFilleul}>
                { typeof filleul === undefined || this.props.filleuls.length === 0
                ? ( <div className="filleul-description">Il semble que tous les filleuls aient été répartis !</div> )
                : (
                    <div className="filleul-description" style={{'display': 'flex', 'flexDirection': 'column',
                        'height': '100%'}}>
                        <div className="list-attributes">
                            <ul style={styleNoBullet}>
                                <li>Prénom : { filleul.firstName }</li>
                                <li>Nom : { filleul.name }</li>
                                <li>Centre : { filleul.center }</li>
                                <li>Année : { filleul.year }</li>
                                <li>Filière : { filleul.pathway }</li>
                                <li>Equipe L1 : { filleul.equipeL1 }</li>
                                <li>Commentaires : { filleul.comments }</li>
                            </ul>
                        </div>
                        <div style={styleBtns}>
                            <div className="btn-next-previous" style={styleBtns}>
                                <Button
                                    variant="primary"
                                    onClick={this.handlePrevFilleul}
                                    disabled={this.props.filleulActuel === 0}
                                >{'<'}</Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleNextFilleul}
                                    disabled={this.props.filleulActuel+1 === this.props.totalFilleuls}
                                >{'>'}</Button>
                            </div>
                            <div className="counter filleul-counter">
                                { this.props.filleulActuel + 1 } / { this.props.totalFilleuls }
                            </div>
                        </div>
                    </div>
                    )
                }
            </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls }) {
    return {
        'parrains': parrains.tousParrainsDispos,
        'filleuls': filleuls.filleuls,
        'filleulActuel': filleuls.filleulActuel,
        'totalFilleuls': filleuls.filleuls.length
    }
}

export default connect(mapStateToProps)(ModuleFilleuls);