import React, {Component} from 'react'
import {connect} from "react-redux";

class RecapCorrespondance extends Component {
    render() {
        const errorCorrespondance = {
            'color': 'red'
        };
        const warningCorrespondance = {
            'color': 'orange'
        };
        const styleNoBullet = {
            'listStyleType': 'none',
            'padding': 0
        };

        const parrainActuel = this.props.parrains[this.props.parrainActuel];
        const filleulActuel = this.props.filleuls[this.props.filleulActuel];
        if(parrainActuel !== undefined && filleulActuel !== undefined) {
            const isSameCenter = parrainActuel.center === filleulActuel.center ? '✔ Centre identique' : (<b style={errorCorrespondance}>✘ Centre différent</b>);
            const isSamePathway = parrainActuel.pathway === filleulActuel.pathway ? '✔ Filière identique' : (<b style={errorCorrespondance}>✘ Filière différente</b>);
            const isSameYear = parrainActuel.year === filleulActuel.year
                ? (<b style={warningCorrespondance}>✔ Année identique</b>)
                : (parrainActuel.year > filleulActuel.year
                        ? '✔ Parrain en année supérieure'
                        : (<b style={errorCorrespondance}>✘✘ Parrain en année inférieure</b>)
                );
            const isSameEquipe = parrainActuel.equipeL1 === filleulActuel.equipeL1 ? '✔ Equipe identique' : (<b style={errorCorrespondance}>✘ Equipe différente</b>);
            return (
                <div className="recap-correspondance">
                    <ul style={styleNoBullet}>
                        <li>{isSameCenter}</li>
                        <li>{isSamePathway}</li>
                        <li>{isSameYear}</li>
                        {(filleulActuel.center === 'Paris' && filleulActuel.pathway === 'Droit' && filleulActuel.year < 'Licence 3' && filleulActuel.equipeL1 !== '' && filleulActuel.equipeL1 !== 'Aucune')
                        && <li>{isSameEquipe}</li>}
                        {(filleulActuel.comments !== '' || parrainActuel.comments !== '')
                        && <li><b style={{'color': 'red'}}>Lire les commentaires !</b></li>}
                    </ul>
                </div>
            )
        }

        return (
            <div className="recap-correspondance">
            </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls }) {
    return {
        'parrainActuel': parrains.parrainActuel,
        'filleulActuel': filleuls.filleulActuel,
        'parrains': parrains.parrains,
        'filleuls': filleuls.filleuls
    }
}

export default connect(mapStateToProps)(RecapCorrespondance);