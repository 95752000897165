import React from 'react';
import './App.css';
import Repartisseur from "./components/Repartisseur";
import {LoadingBar} from "react-redux-loading-bar";
import { Spinner } from 'react-bootstrap';
import { actionLoadInitialData, actionSetSimilarParrains } from "./store/actions";
import {connect} from "react-redux";

class App extends React.Component {
    async componentDidMount() {
        await this.props.dispatch(actionLoadInitialData());
        this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel]))
    }

    render() {
        return (
            <div className="App">
                <LoadingBar updateTime={50} maxProgress={95} progressIncrease={10} />
                {this.props.parrains !== undefined
                    ? (<Repartisseur />)
                    : (
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Chargement...</span>
                        </Spinner>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps({ parrains, filleuls }) {
    return {
        'parrains': parrains.parrains,
        'tousParrainsDispos': parrains.tousParrainsDispos,
        'filleuls': filleuls.filleuls,
        'filleulActuel': filleuls.filleulActuel
    }
}

export default connect(mapStateToProps)(App);