import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './store/reducers'
import middleware from './store/middleware'

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

//require('dotenv').config()

const store = createStore(reducer, middleware);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"),
);
