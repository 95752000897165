import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import { applyMiddleware, compose } from 'redux'

const middlewares = [];
middlewares.push(thunk);
middlewares.push(logger);
middlewares.push(loadingBarMiddleware());

export default compose(
    applyMiddleware(thunk, logger, loadingBarMiddleware()),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f
)
