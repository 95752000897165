import {
    removeParrain,
    setFilteredParrains,
    setParrainActuel,
    setTousParrainsDispos,
    updateParrain,
    setNbMaxFilleulsAtteint
} from './parrains'
import {removeFilleul, setFilleulActuel, setFilleuls, setTousLesFilleulsNonRepartis} from './filleuls'
import {
    _getFilteredParrains,
    _getFilleulNonReparti,
    _matchParrainFilleul,
    _getAllFilleulsNonRepartis,
    _getAllParrainsDispos
} from '../../utils/api'
import React from 'react'
import {firstBy} from "thenby";


export function actionLoadInitialData() {
    return async (dispatch) => {
        await dispatch(actionGetAllParrainsDispos());
        await dispatch(actionGetAllFilleulsNonRepartis());
    }
}

export function actionGetAllParrainsDispos() {
    return async (dispatch) => {
        const data = await _getAllParrainsDispos();
        dispatch(setTousParrainsDispos(data));
        dispatch(setParrainActuel(0));
    }
}

export function actionGetAllFilleulsNonRepartis() {
    console.log('là')
    return async (dispatch) => {
        const data = await _getAllFilleulsNonRepartis();
        dispatch(setTousLesFilleulsNonRepartis(data));
        dispatch(setFilleuls(data));
        dispatch(setFilleulActuel(0));
    }
}

/////////////////////////

export const findBestParrainForFilleul = () => {
    return (dispatch, getState) => {
            if(getState().filleuls.tousFilleulsNonRepartis.length === 0) {
                dispatch(setFilteredParrains([]));
                return;
            }
            
            getState().filleuls.tousFilleulsNonRepartis.forEach((filleul) => {
                const parrainsDispos = getState().parrains.tousParrainsDispos;
    
                // On commence par trouver tous les parrains disponibles en fonction du centre, filière, année >, equipeL1
                let parrains = parrainsDispos.filter((el) => {
                    if (filleul.center === 'Paris' && (filleul.year === 'Licence 1' || filleul.year === 'Licence 2') && filleul.pathway === 'Droit' && (filleul.equipeL1 !== '' && filleul.equipeL1 !== 'Aucune')) {
                        return (el.center === filleul.center
                            && el.pathway === filleul.pathway
                            && el.year > filleul.year
                            && (el.equipeL1 === filleul.equipeL1)
                            && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                        )
                    }
                    return (el.center === filleul.center
                        && el.pathway === filleul.pathway
                        && el.year > filleul.year
                        && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                        )}
                ).sort(firstBy((a, b) => {
                    return b.maxFilleuls - a.maxFilleuls 
                }));
    
                // Si on n'en trouve pas, alors on cherche les parrains disponibles en fonction de la filière et de l'année >=
                if(parrains.length === 0) {
                    parrains = parrainsDispos.filter((el) => {
                        return (el.pathway === filleul.pathway 
                            && el.year >= filleul.year
                            && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                            )}
                    ).sort(firstBy((a, b) => {
                        return b.maxFilleuls - a.maxFilleuls 
                    }));

                    if(parrains.length === 0) {
                        // Si on n'en trouve toujours pas, on affiche tous les parrains compatibles, même s'ils ont dépassé leur quota de filleuls voulus
                        // (mais on affiche un message indiquant cela)
                        parrains = parrainsDispos.filter((el) => {
                            if (filleul.center === 'Paris' && (filleul.year === 'Licence 1' || filleul.year === 'Licence 2') && filleul.pathway === 'Droit' && (filleul.equipeL1 !== '' && filleul.equipeL1 !== 'Aucune')) {
                                return (el.center === filleul.center
                                    && el.pathway === filleul.pathway
                                    && el.year > filleul.year
                                    && (el.equipeL1 === filleul.equipeL1)
                                    && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                                    )
                            }
                            return (el.center === filleul.center 
                                && el.pathway === filleul.pathway 
                                && el.year > filleul.year
                                && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                                )}
                        ).sort(firstBy((a, b) => {
                            return b.maxFilleuls - a.maxFilleuls 
                        }));
        
                        dispatch(setNbMaxFilleulsAtteint(true));
                    }
                }
    
                // Si on a trouvé un ou plusieurs parrain(s) potentiel(s), on match le filleul avec le premier de la liste
                dispatch(setFilteredParrains(parrains));
                dispatch(setParrainActuel(0));
    
                if(parrains.length > 0) {
                    dispatch(actionMatchPairAutomatic(parrains[0], filleul));
                    dispatch(setNbMaxFilleulsAtteint(false));
                    dispatch(removeFilleul(filleul));

                    parrains[0]['maxFilleuls'] = parrains[0]['maxFilleuls']-1;
                    getState().app.showParrainsWithAlreadyEnoughFilleuls ? dispatch(updateParrain(parrains[0])) : dispatch(removeParrain(parrains[0]['@id']));
                    dispatch(setParrainActuel(0));
                }
            });
    }
}



/////////////////////////

export function actionSetSimilarParrains(filleul) {
    return (dispatch, getState) => {
        if(getState().filleuls.tousFilleulsNonRepartis.length === 0) {
            dispatch(setFilteredParrains([]));
            return;
        }
        const parrainsDispos = getState().parrains.tousParrainsDispos;

        // On commence par trouver tous les parrains disponibles en fonction du centre, filière, année >, equipeL1
        let parrains = parrainsDispos.filter((el) => {
            if (filleul.center === 'Paris' && (filleul.year === 'Licence 1' || filleul.year === 'Licence 2') && filleul.pathway === 'Droit' && (filleul.equipeL1 !== '' && filleul.equipeL1 !== 'Aucune')) {
                return (el.center === filleul.center
                    && el.pathway === filleul.pathway
                    && el.year > filleul.year
                    && (el.equipeL1 === filleul.equipeL1)
                    && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                    )
            }
            return (el.center === filleul.center 
                && el.pathway === filleul.pathway 
                && el.year > filleul.year
                && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                )}
        ).sort(firstBy((a, b) => {
            return b.maxFilleuls - a.maxFilleuls 
        }));

        // Si on n'en trouve pas, alors on cherche les parrains disponibles en fonction de la filière et de l'année >=
        if(parrains.length === 0) {
            parrains = parrainsDispos.filter((el) => {
                return (el.pathway === filleul.pathway 
                    && el.year >= filleul.year
                    && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                    )}
            ).sort(firstBy((a, b) => {
                return b.maxFilleuls - a.maxFilleuls 
            }));

            if(parrains.length === 0) {
                // Si on n'en trouve toujours pas, on affiche tous les parrains compatibles, même s'ils ont dépassé leur quota de filleuls voulus
                // (mais on affiche un message indiquant cela)
                parrains = parrainsDispos.filter((el) => {
                    if (filleul.center === 'Paris' && (filleul.year === 'Licence 1' || filleul.year === 'Licence 2') && filleul.pathway === 'Droit' && (filleul.equipeL1 !== '' && filleul.equipeL1 !== 'Aucune')) {
                        return (el.center === filleul.center
                            && el.pathway === filleul.pathway
                            && el.year > filleul.year
                            && (el.equipeL1 === filleul.equipeL1)
                            && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                        )
                    }
                    return (el.center === filleul.center 
                        && el.pathway === filleul.pathway 
                        && el.year > filleul.year
                        && (getState().app.showParrainsWithAlreadyEnoughFilleuls ? (el.maxFilleuls > -getState().parrains.maxAdditionalFilleuls) : (el.maxFilleuls > 0))
                        )}
                ).sort(firstBy((a, b) => {
                    return b.maxFilleuls - a.maxFilleuls 
                }));
                dispatch(setNbMaxFilleulsAtteint(true));
            }
        }

        dispatch(setFilteredParrains(parrains));
        dispatch(setParrainActuel(0));
    }
}

/*export function actionGetFilleulNonReparti() {
    return (dispatch) => {
        //dispatch(showLoading());
        return _getFilleulNonReparti()
            .then((filleuls) => {
                let filleulActuel = {};
                if (Array.isArray(filleuls)) {
                    filleulActuel = filleuls[0]
                } else {
                    filleulActuel = filleuls;
                }
                dispatch(setFilleulActuel(0));
                dispatch(setFilleuls(filleuls));
                return filleulActuel;
            }).then((filleulActuel) => {
                return filteredParrainsRequest(filleulActuel, dispatch);
            }
        );
    }
}*/

export function actionMatchPairAutomatic(parrain, filleul) {
    return async(dispatch, getState) => {
        // On crée la Pair via l'API
        await _matchParrainFilleul(parrain, filleul).then(() => {
            // Si on n'en est pas au dernier filleul de la liste, on conserve l'index du filleul actuel. Sinon, on passe au précédent.
            dispatch(setFilleulActuel(getState().filleuls.filleulActuel < getState().filleuls.tousFilleulsNonRepartis-1 ? getState().filleuls.filleulActuel : getState().filleuls.filleulActuel));
            dispatch(setNbMaxFilleulsAtteint(false));
        });
    }
}

export function actionMatchPair(parrain, filleul) {
    return async(dispatch, getState) => {
        // On crée la Pair via l'API
        _matchParrainFilleul(parrain, filleul).then(() => {
            dispatch(removeFilleul(filleul));

            // Si on n'en est pas au dernier filleul de la liste, on conserve l'index du filleul actuel. Sinon, on passe au précédent.
            dispatch(setFilleulActuel(getState().filleuls.filleulActuel < getState().filleuls.tousFilleulsNonRepartis-1 ? getState().filleuls.filleulActuel : getState().filleuls.filleulActuel));
            /*if(parrain['maxFilleuls']-1 <= 0) {
                parrain['maxFilleuls'] = parrain['maxFilleuls']-1;
                dispatch(removeParrain(parrain['@id']));
                dispatch(setParrainActuel(0));
            } else {
                parrain['maxFilleuls'] = parrain['maxFilleuls']-1;
                dispatch(updateParrain(parrain));
                dispatch(setParrainActuel(0));
            }*/
            parrain['maxFilleuls'] = parrain['maxFilleuls']-1;
            getState().app.showParrainsWithAlreadyEnoughFilleuls ? dispatch(updateParrain(parrain)) : dispatch(removeParrain(parrain['@id']));
            dispatch(setParrainActuel(0));
            
            dispatch(setNbMaxFilleulsAtteint(false));
        });
    }
}


export function actionPassFilleulActuel() {
    return (dispatch, getState) => {
        dispatch(removeFilleul(getState().filleuls.filleuls[getState().filleuls.filleulActuel]));
        dispatch(setFilleulActuel(0));
        dispatch(actionSetSimilarParrains(getState().filleuls.filleuls[0]))
    }
}


/*function filteredParrainsRequest(filleulActuel, dispatch) {
    return _getFilteredParrains(filleulActuel)
        .then((parrains) => {
            dispatch(setParrainActuel(0));
            dispatch(setFilteredParrains(parrains))
        }).catch(err => {
            console.log(err);
        })
}*/
