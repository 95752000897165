export const SET_FILLEUL_ACTUEL = 'SET_FILLEUL_ACTUEL';
export const SET_FILLEULS = 'SET_FILLEULS';
export const REMOVE_FILLEUL = 'REMOVE_FILLEUL';
export const SET_TOUS_LES_FILLEULS_NON_REPARTIS = 'SET_TOUS_LES_FILLEULS_NON_REPARTIS';

export function setTousLesFilleulsNonRepartis(tousFilleulsNonRepartis) {
    return {
        type: SET_TOUS_LES_FILLEULS_NON_REPARTIS,
        tousFilleulsNonRepartis
    }
}

export function setFilleulActuel(filleulActuel) {
    return {
        type: SET_FILLEUL_ACTUEL,
        filleulActuel: filleulActuel
    }
}

export function setFilleuls(filleuls) {
    return {
        type: SET_FILLEULS,
        filleuls: filleuls
    }
}

export function removeFilleul(filleul) {
    return {
        type: REMOVE_FILLEUL,
        filleulId: filleul['@id']
    }
}
