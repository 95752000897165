export const TOGGLE_VIEW = 'TOGGLE_VIEW';
export const TOGGLE_SHOW_PARRAINS_WITH_ALREADY_ENOUGH_FILLEULS = 'TOGGLE_SHOW_PARRAINS_WITH_ALREADY_ENOUGH_FILLEULS';

export function toggleView() {
    return {
        'type': TOGGLE_VIEW
    }
}

export function toggleShowParrainsWithAlreadyEnoughFilleuls() {
    return {
        'type': TOGGLE_SHOW_PARRAINS_WITH_ALREADY_ENOUGH_FILLEULS
    }
}