 import { combineReducers } from 'redux'
 import { loadingBarReducer } from 'react-redux-loading-bar'
 import parrains from './parrains'
 import filleuls from './filleuls'
 import app from './app'


export default combineReducers({
    parrains,
    filleuls,
    app,
    loadingBar: loadingBarReducer,
})