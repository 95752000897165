import {
    REMOVE_PARRAIN,
    SET_FILTERED_PARRAINS,
    SET_PARRAIN_ACTUEL,
    SET_TOUS_LES_PARRAINS_DISPOS, UPDATE_PARRAIN,
    ADD_PARRAIN_TO_FILTERED,
    SET_NB_MAX_FILLEULS_ATTEINT,
    MAX_ADDITIONAL_FILLEULS
} from '../actions/parrains';

export default function parrains (state = {'maxAdditionalFilleuls': 0}, action) {
    switch(action.type) {
        case SET_TOUS_LES_PARRAINS_DISPOS:
            return {
                ...state,
                'tousParrainsDispos': action.tousParrainsDispos
            };

        case SET_FILTERED_PARRAINS:
            return {
                ...state,
                'parrains': action.parrains
            };
        case SET_PARRAIN_ACTUEL:
            return {
                ...state,
                'parrainActuel': action.parrainActuel
            };
        case REMOVE_PARRAIN:
            const parrainToRemoveIdx = state.tousParrainsDispos.findIndex((el) => el['@id'] === action.parrainId);
            return {
                ...state,
                'tousParrainsDispos': [
                    ...state.tousParrainsDispos.slice(0, parrainToRemoveIdx),
                    ...state.tousParrainsDispos.slice(parrainToRemoveIdx+1),
                ]
            };
        case UPDATE_PARRAIN:
            return {
                ...state,
                'tousParrainsDispos': state.tousParrainsDispos.map(parrain => {
                    if(parrain['@id'] === action.parrain['@id']) {
                        return {
                            ...parrain,
                            'maxFilleuls': parrain.maxFilleuls-1
                        }
                    }
                    return parrain;
                })
            };
        case ADD_PARRAIN_TO_FILTERED:
            return {
                ...state,
                'parrains': action.parrain.concat(state.parrains)
            }
        case SET_NB_MAX_FILLEULS_ATTEINT:
            return {
                ...state,
                'nbMaxFilleulsAtteint': action.isNbMaxFilleulsAtteint
            }
        case MAX_ADDITIONAL_FILLEULS:
            return {
                ...state,
                'maxAdditionalFilleuls': action.maxAdditionalFilleuls
            }
        default:
            return state;
    }
}