import React from 'react'
import {connect} from "react-redux";
import { actionMatchPair, actionPassFilleulActuel, actionSetSimilarParrains, findBestParrainForFilleul } from "../store/actions";
import { Spinner } from 'react-bootstrap'
import TableViewRepartition from "./TableViewRepartition";
import {toggleView, toggleShowParrainsWithAlreadyEnoughFilleuls} from "../store/actions/app";
import { changeMaxAdditionalFilleuls } from "../store/actions/parrains";
import IndividualViewRepartition from "./IndividualViewRepartition";

class Repartisseur extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filleulActuel: {},
            parrainsPotentiels: [],
            parrainActuel: {},
            maxAdditionalFilleuls: 0
        };

        this.handlePassFilleul = this.handlePassFilleul.bind(this);
        this.handleMatchFilleulParrain = this.handleMatchFilleulParrain.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.handleMatchAllFilleulsWithParrains = this.handleMatchAllFilleulsWithParrains.bind(this);
        this.handleShowParrainsWithAlreadyEnoughFilleuls = this.handleShowParrainsWithAlreadyEnoughFilleuls.bind(this);
        this.handleChangeMaxAdditionalFilleuls = this.handleChangeMaxAdditionalFilleuls.bind(this);
    }

    componentDidMount() {
            this.setState({
                ...this.state,
                'parrains': this.props.parrains,
                'filleuls': this.props.filleuls,
                'filleulActuel': this.props.filleulActuel,
                'parrainActuel': this.props.parrainActuel,
                'showParrainsWithAlreadyEnoughFilleuls': this.props.showParrainsWithAlreadyEnoughFilleuls,
            })
    }

    toggleView() {
        this.props.dispatch(toggleView())
    }

    async handleMatchFilleulParrain() {
        const parrainIdx = this.props.parrainActuel;
        const filleulIdx = this.props.filleulActuel;
        await this.props.dispatch(actionMatchPair(this.props.parrains[parrainIdx], this.props.filleuls[filleulIdx]));
        await this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[filleulIdx]));
    }

    handlePassFilleul() {
        this.props.dispatch(actionPassFilleulActuel())
    }

    handleMatchAllFilleulsWithParrains() {
        this.props.dispatch(findBestParrainForFilleul());
    }

    handleShowParrainsWithAlreadyEnoughFilleuls() {
        this.props.dispatch(toggleShowParrainsWithAlreadyEnoughFilleuls(!this.state.showParrainsWithAlreadyEnoughFilleuls))
        this.props.dispatch(actionSetSimilarParrains(this.props.filleuls[this.props.filleulActuel]));        
    }

    handleChangeMaxAdditionalFilleuls(event) {
        const val = parseInt(event.target.value);
        this.setState({
            ...this.state,
            'maxAdditionalFilleuls': val
        });
        this.props.dispatch(changeMaxAdditionalFilleuls(val));
    }
    
    
    render() {
        if(this.props.tousParrainsDispos !== undefined && Array.isArray(this.props.tousParrainsDispos) && this.props.tousParrainsDispos.length !== 0) {
            return (
                <div className="repartisseur">
                    {/*<a href='/#' onClick={this.toggleView}>Toggle View</a><br />*/}
                    <label>
                        <input type="checkbox" value={this.state.showParrainsWithAlreadyEnoughFilleuls} onChange={this.handleShowParrainsWithAlreadyEnoughFilleuls} />
                          Élargir la recherche aux parrains ayant déjà atteint leur quota de filleuls, en permettant d'aller jusqu'à <input type="number" min="0" value={this.state.maxAdditionalFilleuls} onChange={this.handleChangeMaxAdditionalFilleuls}/> filleul(s) supplémentaire(s)                    </label><br />
                    <a href='/#' onClick={this.handleMatchAllFilleulsWithParrains}>Match ALL</a>
                        { this.props.view === 'module'
                            ? (<div className="table-view" style={{'display': 'flex', 'justifyContent': 'center'}}>
                                <IndividualViewRepartition />
                            </div>)
                            : (<div className="table-view" style={{'display': 'flex', 'justifyContent': 'center'}}>
                                <TableViewRepartition />
                            </div>)
                        }

                </div>
            )
        }

        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Chargement...</span>
            </Spinner>
        )
    }
}

function mapStateToProps({ parrains, filleuls, app }) {
    return {
        'parrains': parrains.parrains,
        'filleuls': filleuls.filleuls,
        'filleulActuel': filleuls.filleulActuel,
        'parrainActuel': parrains.parrainActuel,
        'tousParrainsDispos': parrains.tousParrainsDispos,
        'view': app.view,
        'showParrainsWithAlreadyEnoughFilleuls': app.showParrainsWithAlreadyEnoughFilleuls,
        'maxAdditionalFilleuls': parrains.maxAdditionalFilleuls
    }
}

export default connect(mapStateToProps)(Repartisseur);