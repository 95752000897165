import {
    REMOVE_FILLEUL,
    SET_FILLEUL_ACTUEL,
    SET_FILLEULS,
    SET_TOUS_LES_FILLEULS_NON_REPARTIS
} from '../actions/filleuls';

export default function filleuls (state = {}, action) {
    switch(action.type) {
        case SET_TOUS_LES_FILLEULS_NON_REPARTIS:
            return {
                ...state,
                'tousFilleulsNonRepartis': action.tousFilleulsNonRepartis
            }
        case SET_FILLEUL_ACTUEL:
            return {
                ...state,
                'filleulActuel': action.filleulActuel
            };
        case SET_FILLEULS:
            return {
                ...state,
                'filleuls': action.filleuls
            };
        case REMOVE_FILLEUL:
            const filleulToRemoveIdx = state.filleuls.findIndex((el) => el['@id'] === action.filleulId);
            return {
                ...state,
                'filleuls': [
                    ...state.filleuls.slice(0, filleulToRemoveIdx),
                    ...state.filleuls.slice(filleulToRemoveIdx+1),
                ],
                'tousFilleulsNonRepartis': [
                    ...state.filleuls.slice(0, filleulToRemoveIdx),
                    ...state.filleuls.slice(filleulToRemoveIdx+1),
                ]
            };
        default:
            return state;
    }
}