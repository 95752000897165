import React, { Component } from 'react';
import {connect} from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import {addParrainToFiltered} from "../store/actions/parrains";

const ref = React.createRef();

class SelectAjoutParrainManuel extends Component {
    constructor(props) {
        super(props);
        this.handleOnSelectParrainDansListe = this.handleOnSelectParrainDansListe.bind(this)

        this.state = {
            "tousParrainsDispos": this.props.tousParrainsDispos.filter((el) => {
                return !this.props.parrains.includes(el);
            }).sort((a, b) => { return a.fullName.toUpperCase() >= b.fullName.toUpperCase() }),
            "selected": {}
        };
    }




    handleOnSelectParrainDansListe(selected) {
        if(selected.length !== 0) {
            this.props.dispatch(addParrainToFiltered(selected));
            this.setState({
                ...this.state,
                "tousParrainsDispos": this.state.tousParrainsDispos.filter((el) => { return el !== selected[0] })
            });
            ref.current.clear();
        }
    }

    render() {
        return (
            <div style={{'padding': '30px'}}>
                <p>Rajouter un parrain à la liste</p>
                <Typeahead
                    id="typeahead-add-parrain"
                    ref={ref}
                    onChange={(selected) => this.handleOnSelectParrainDansListe(selected)}
                    options={this.state.tousParrainsDispos}
                    labelKey="fullName"
                    filterBy={['pathway', 'year', 'comments']}
                />
            </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls }) {
    return {
        'parrains': parrains.parrains,
        'parrainActuel': parrains.parrainActuel,
        'tousParrainsDispos': parrains.tousParrainsDispos,
    }
}

export default connect(mapStateToProps)(SelectAjoutParrainManuel);