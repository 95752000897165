import React, { Component } from 'react'
import { connect } from "react-redux";
import {setParrainActuel} from "../store/actions/parrains";
import {Button} from "react-bootstrap";

class ModuleParrains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parrainActuel: {}
        };

        this.handlePrevParrain = this.handlePrevParrain.bind(this);
        this.handleNextParrain = this.handleNextParrain.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            'parrains': this.props.parrains,
            'parrainActuel': this.props.parrainActuel,
            'currentParrainIndex': this.props.parrainActuel,
            'maxParrainIndex': this.props.totalParrains,
        })
    }

    handlePrevParrain() {
        if(this.state.currentParrainIndex > 0) {
            this.props.dispatch(setParrainActuel(this.state.currentParrainIndex-1));
            this.setState({
                ...this.state,
                'currentParrainIndex': this.state.currentParrainIndex-1,
                'parrainActuel': this.props.parrains[this.state.currentParrainIndex-1]
            })
        }
    }

    handleNextParrain() {
            if(this.props.parrainActuel < this.props.totalParrains) {
            this.props.dispatch(setParrainActuel(this.props.parrainActuel+1));
            this.setState({
                ...this.state,
                'currentParrainIndex': this.props.parrainActuel+1,
                'parrainActuel': this.props.parrains[this.props.parrainActuel+1]
            })
        }
    }

    render() {
        const styleModuleSelectionParrain = {
            'width': '30%',
            'height': '300px'
        };

        const styleBtns = {
            'marginTop': 'auto'
        }


        const styleNoBullet = {
            'listStyleType': 'none',
            'padding': 0
        };

        const parrain = this.props.parrains[this.props.parrainActuel];
        const currentIndex = this.props.parrainActuel;

        return (
            <div className="module module-selection-parrain" style={styleModuleSelectionParrain}>
                {(this.props.totalFilleuls === 0 || (typeof parrain === undefined || this.props.parrains === undefined) || !Array.isArray(this.props.parrains) || this.props.parrains.length === 0)
                ? (<div className="parrain">Aucun parrain n'est compatible avec ce filleul pour le moment. Notez son nom et transmettez-le à Mehdi pour attribution manuelle</div>)
                : (
                    <div className="parrain"  style={{'display': 'flex', 'flexDirection': 'column', 'height': '100%'}}>
                        <div className="list-attributes">
                            <ul style={styleNoBullet}>
                                <li>Prénom : { parrain.firstName }</li>
                                <li>Nom : { parrain.name }</li>
                                <li>Centre : { parrain.center }</li>
                                <li>Année : { parrain.year }</li>
                                <li>Filière : { parrain.pathway }</li>
                                <li>Equipe L1 : { parrain.equipeL1 }</li>
                                <li>Max Filleuls : { parrain.maxFilleuls }</li>
                                <li>Commentaires : { parrain.comments }</li>
                            </ul>
                        </div>
                        <div style={styleBtns}>
                            <div className="btn-next-previous" style={styleBtns}>
                                <Button
                                    variant="primary"
                                    onClick={this.handlePrevParrain}
                                    disabled={currentIndex === 0}
                                >{'<'}</Button>
                                <Button
                                    variant="primary"
                                    onClick={this.handleNextParrain}
                                    disabled={currentIndex+1 === this.props.totalParrains}
                                >{'>'}</Button>
                            </div>
                            <div className="counter parrain-counter">
                                { currentIndex + 1} / { this.props.totalParrains }
                            </div>
                        </div>
                    </div>)
                }
            </div>
        )
    }
}

function mapStateToProps({ parrains, filleuls }) {
    return {
        'parrains': parrains.parrains,
        'parrainActuel': parrains.parrainActuel,
        'totalParrains': parrains.parrains.length,
        'totalFilleuls': filleuls.filleuls.length
    }
}

export default connect(mapStateToProps)(ModuleParrains);