import axios from 'axios'
export const URL_FILTERED_PARRAINS = process.env.REACT_APP_API_URL + 'parrains?';
export const URL_GET_FILLEULS_NON_REPARTIS = process.env.REACT_APP_API_URL + 'filleuls?!parrain&pagination=false';
export const URL_MATCH_PAIR = process.env.REACT_APP_API_URL + 'pair';
export const URL_GET_PARRAINS_DISPOS = process.env.REACT_APP_API_URL + 'parrains?pagination=false';



export async function _getAllParrainsDispos() {
    try {
        const data = await axios.get(URL_GET_PARRAINS_DISPOS);
        return data.data;
    } catch (err) {
        console.log(err);
    }
}

export async function _getAllFilleulsNonRepartis() {
    console.log("ici");
    try {
        const data = await axios.get(URL_GET_FILLEULS_NON_REPARTIS);
        console.log(data);
        return data.data;
    } catch (err) {
        console.log(err);
    }
}

export function getParamsForFilteredParrainsQuery(filleul) {
    let paramsString = '';
    paramsString += filleul.center === '' ? '' : ('&center=' + filleul.center);
    paramsString += filleul.pathway === '' ? '' : ('&pathway=' + filleul.pathway);
    if(filleul.center === 'Paris' && filleul.pathway === 'Droit' && (filleul.year === 'Licence 1' || filleul.year === 'Licence 2' )) {
        paramsString += '&equipeL1=' + filleul.equipeL1;
    }

    if(filleul.year === 'Licence 1') {
        //paramsString += '&year[]=Licence%201&year[]=Licence%202&year[]=Licence%203&year[]=Master%201&year[]=Master%202'
        paramsString += '&year[]=Licence%202&year[]=Licence%203&year[]=Master%201&year[]=Master%202'
    } else if (filleul.year === 'Licence 2') {
        //paramsString += '&year[]=Licence%202&year[]=Licence%203&year[]=Master%201&year[]=Master%202';
        paramsString += '&year[]=Licence%203&year[]=Master%201&year[]=Master%202';
    } else if (filleul.year === 'Licence 3') {
        //paramsString += '&year[]=Licence%203&year[]=Master%201&year[]=Master%202';
        paramsString += '&year[]=Master%201&year[]=Master%202';
    } else if (filleul.year === 'Master 1') {
        //paramsString += '&year[]=Master%201&year[]=Master%202';
        paramsString += '&year[]=Master%201&year[]=Master%202';
    } else if (filleul.year === 'Master 2') {
        paramsString += '&year[]=Master%201&year[]=Master%202';
    }

    return paramsString;
}


export async function _getFilteredParrains(filleul) {
    let url = URL_FILTERED_PARRAINS + getParamsForFilteredParrainsQuery(filleul);

    try {
        return (await axios.get(url)).data();
    } catch (err) {
        console.log(err);
    }
}

export async function _getFilleulNonReparti() {
    try {
        const res = await axios.get(URL_GET_FILLEULS_NON_REPARTIS);
        return await res.data;
    } catch (err) {
        console.log(err);
    }
}

export async function _matchParrainFilleul(parrain, filleul) {
    const params = {'filleul': filleul['id'], 'parrain': parrain['id']};

    return axios.get(URL_MATCH_PAIR + "/" + params.parrain + "/" + params.filleul)
        .then((res) => {
            console.log("AllGood");
        }).catch((err) => {
            console.log("Holaaa, erreur là !")
        })
}